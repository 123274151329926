function buildConfigFilename(hostname, env = 'prod') {
  if (!hostname) {
    hostname = window.location.hostname;
  }

  return `${hostname}-${env}-config.json`;
}

module.exports = {
  buildConfigFilename,
};
