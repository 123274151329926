import Promise from 'es6-promise';
import 'whatwg-fetch';

const { COOKIE_NAME, PPID_COOKIE_NAME, Util } = require('./commons');
const DomainMap = require('./domainMapping');

// Commenting below code, refer xdidp comment 2 in changelog
/* const SWITCH_OVER_THIRD_PARTY_URL = 'https://www.expedia.com'; */


function setCookies(url, xdidString, params, hostname) {
  return Util.setLocalXdid(xdidString, params)
    .then((pix) => {
      if (pix === null) {
        console.error('Did not successfully set first-party xdid');
        return pix;
      }
      // Commenting below code, refer xdidp comment 2 in changelog
      /* if (SWITCH_OVER_THIRD_PARTY_URL.replace(/^https?:\/\//, '') !== hostname) {
        Util.setSwitchOverXdid(SWITCH_OVER_THIRD_PARTY_URL, pix.xdidString(), params);
      } */

      const thirdPartySetPromise = Util.setThirdPartyXdid(url, pix.xdidString(), params);

      if (pix && pix.xdidString() === xdidString) {
        return pix.xdidString();
      }
      return thirdPartySetPromise.then(thirdPartyPix => (thirdPartyPix ? thirdPartyPix.xdidString() : null));
    }).then((xdidString) => {
      localStorage.setItem(COOKIE_NAME, xdidString);
      return xdidString;
    });
}

class Waiter {
  constructor(domainName, env, cdnUrl) {
    this.domainName = domainName;
    if (!this.domainName) {
      throw new Error('Illegal arguments exception');
    }
    this.domainMap = new DomainMap(domainName, env, cdnUrl);
  }

  _findXdid(params) {
    const thirdPartyPixelUrl = this.domainMap.thirdPartyPixelUrl();

    const urls = this.domainMap.xShopUrls() || [];
    let optout = false;

    const next = (resp, i) => resp.then((pix) => {
      optout = optout || (pix && pix.optout);

      if (pix && pix.hasXdid()) {
        return Promise.resolve(pix);
      }

      if (i >= urls.length) {
        return Promise.resolve(null);
      }

      return next(Util.queryXdid(urls[i]), i + 1);
    });

    const pixPromise = next(Util.queryXdid(thirdPartyPixelUrl), 0);

    return pixPromise.then((pix) => {
      const xdidString = pix === null ? null : pix.xdidString();
      if (!optout) {
        return setCookies(thirdPartyPixelUrl, xdidString, params, this.domainName);
      }
      return Promise.resolve(xdidString);
    });
  }

  loadXdidApi(params) {
    const self = this;
    const xdidValue = Util.getCookieValue(COOKIE_NAME);
    const ppidValue = Util.getCookieValue(PPID_COOKIE_NAME);
    // Commenting below code, refer xdidp comment 1 in changelog
    /* params.screenWidth = screen.width;
    params.screenHeight = screen.height;
    params.screenAvailWidth = screen.availWidth;
    params.screenAvailHeight = screen.availHeight;
    params.screenColorDepth = screen.colorDepth;
    params.screenPixelDepth = screen.pixelDepth;
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)) {
      params.isMobile = 1;
    }
    if (/iPad|iPhone|iPod/i.test(navigator.userAgent)) {
      params.isIphone = 1;
    } */

    const canTrack = Util.canTrack();
    const ppidExist = Util.cookieExists(ppidValue);
    if (ppidExist && !canTrack) {
      Util.deleteCookie(PPID_COOKIE_NAME);
    }
    /*
    Do not fire first-party domain request to travel-pixel service when
    1. xdid cookie is set
     */
    if (Util.cookieExists(xdidValue)) {
      // Commenting below code, refer xdidp comment 2 in changelog
      // Util.setSwitchOverXdid(SWITCH_OVER_THIRD_PARTY_URL, xdidValue, params);
      return Util.setThirdPartyXdid(this.domainMap.thirdPartyPixelUrl(), xdidValue, params)
        .then(pix => (pix && pix.hasXdid() ? pix.xdidString() : null))
        .then((xdidString) => {
          localStorage.setItem(COOKIE_NAME, xdidString);
          return xdidString;
        });
    }

    // Do fire first-party domain request to travel-pixel service to set cookie
    return this.domainMap.load()
      .then(() => self._findXdid(params));
  }
}

function push(fn) {
  if (typeof fn === 'function') {
    fn(Waiter);
  }
}

// There is no reason to use this for require/commonjs since they guarantee loading order.
if (window) {
  const _savedCbs = window.travelPixel;

  // If there were async callbacks then call them now that we are loaded
  if (_savedCbs && _savedCbs.length > 0 && _savedCbs.forEach) {
    _savedCbs.forEach((cb) => {
      push(cb);
    });
  }
}

module.exports = {
  push,
  Waiter,
  // Commenting below code, refer xdidp comment 2 in changelog
  /* SWITCH_OVER_THIRD_PARTY_URL, */
};
