import Promise from 'es6-promise';
import 'whatwg-fetch';

const Config = require('./config');

const CDN_URL = 'https://b.travel-assets.com/travel-pixel-js/1.0.0';
const CONFIG_ROOT = 'config';

const thirdPartyUrls = {};
let defaultThirdParty;

thirdPartyUrls.prod = 'https://www.trvl-px.com/trvl-px/v2';
defaultThirdParty = thirdPartyUrls.prod;

// This will be stripped out of the "production" webpack build so we don't leak internal configurations
if (process.env.NODE_ENV !== 'production') {
  thirdPartyUrls.dev = 'https://travel-pixel.sandbox.dev.sb.karmalab.net:8443/trvl-px/v2';
  thirdPartyUrls.int = 'https://travel-pixel.exp-test.net/trvl-px/v2';

  defaultThirdParty = thirdPartyUrls.dev;
}

class DomainMap {
  constructor(hostname, env, cdnUrl) {
    this.hostname = hostname;
    this.env = env;
    this.cdnUrl = cdnUrl || CDN_URL;
    this._loaded = false;
    this.config = null;
    this.fetchOpts = {};

    this.thirdPartyPixelUrlValue = thirdPartyUrls[this.env] || defaultThirdParty;
  }

  load() {
    const self = this;

    if (this._loaded && this.config) {
      return Promise.resolve(this.config);
    }

    const configFileName = Config.buildConfigFilename(this.hostname, this.env);
    if (!configFileName) {
      return Promise.reject('Failed to build config asset name');
    }

    const assetUrl = `${this.cdnUrl}/${CONFIG_ROOT}/${configFileName}`;
    return window.fetch(assetUrl, this.fetchOpts)
      .then((res) => {
        if (res.ok) {
          return res.json();
        }

        // Returning default xShopUrls as per EUK-186 instead of throwing error.
        return {
          "env": this.env,
          "name": "expediaDC",
          "domain": this.hostname,
          "xshopUrls": []
        };
      })
      .then((json) => {
        self._loaded = true;
        self.config = json;

        return json;
      }).catch((error) => {
        console.error(error);
        return error;
      });
  }

  xShopUrls() {
    if (!this._loaded) {
      throw new TypeError('Must call load() prior to calling next()');
    }

    const nextSet = this.config && this.config.xshopUrls;
    if (nextSet) {
      return nextSet;
    }

    return [];
  }

  thirdPartyPixelUrl() {
    return this.thirdPartyPixelUrlValue;
  }
}

module.exports = DomainMap;
