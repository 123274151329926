import 'whatwg-fetch';

const PixResponse = require('./pixelresponse');

const COOKIE_NAME = 'xdid';
const PPID_COOKIE_NAME = 'eg_ppid';
const EU_SITE_COOKIE = 'eu-site';
const BEX_HCOM_CONSENT_COOKIE = 'OIP';
const VRBO_CONSENT_COOKIE = 'ha-cookie-settings';


function parseFetchResponse(fetchUrl, res) {
  if (res.ok) {
    return res.json();
  }

  throw new Error(`Fetch failed for ${fetchUrl} with status ${res.status}`);
}

class Util {
  static getPix(response) {
    try {
      if (response) {
        return new PixResponse(response);
      }
    } catch (error) {
      console.error(error);
    }
    return null;
  }

  static queryXdid(url) {
    const queryUrl = `${url}/get`;

    /* eslint-env browser */
    return window.fetch(queryUrl, {
      credentials: 'include',
    }).then(res => parseFetchResponse(url, res)).then(json => Util.getPix(json)).catch((error) => {
      console.error(error);
      return null;
    });
  }

  static setLocalXdid(val, params) {
    return Util.setXdid('/trvl-px/v2/pix', val, params);
  }

  // Commenting below code, refer xdidp comment 2 in changelog
  /* static setSwitchOverXdid(url, val, params) {
    return Util.setXdidSimpleRequest(`${url}/trvl-px/v2/pix`, val, params);
  } */

  static setThirdPartyXdid(url, val, params) {
    return Util.setXdid(`${url}/pix`, val, params);
  }

  static setXdidSimpleRequest(url, val, params) {
    /* eslint-env browser */
    let rq = null;
    if (val) {
      rq = JSON.stringify({ xdid: val });
    }
    const fetchUrl = `${url}?${this.objectToQueryString(params)}`;
    return window.fetch(fetchUrl, {
      method: 'POST',
      credentials: 'include',
      body: rq,
    }).then(res => parseFetchResponse(fetchUrl, res)).then(json => Util.getPix(json)).catch((error) => {
      console.error(error);
      return null;
    });
  }

  static setXdid(url, val, params) {
    /* eslint-env browser */
    let rq = null;
    if (val) {
      rq = JSON.stringify({ xdid: val });
    }
    const fetchUrl = `${url}?${this.objectToQueryString(params)}`;
    return window.fetch(fetchUrl, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: rq,
    }).then(res => parseFetchResponse(fetchUrl, res)).then(json => Util.getPix(json)).catch((error) => {
      console.error(error);
      return null;
    });
  }

  static objectToQueryString(obj) {
    const params = [];
    if (!obj || typeof obj.hasOwnProperty !== 'function') {
      return '';
    }

    for (const key in obj) {
      if (!obj.hasOwnProperty(key)) {
        continue;
      }

      let val = obj[key];
      val = val || '';

      params.push(`${encodeURIComponent(key)}=${encodeURIComponent(val)}`);
    }

    return params.join('&');
  }

  static getCookieValue(cookieName, cookieString) {
    if (typeof cookieString !== 'string') {
      if (document && document.cookie) {
        cookieString = document.cookie;
      } else {
        return null;
      }
    }

    const cookieValues = cookieString.match(`(^|[^;]+)\\s*${cookieName}\\s*=\\s*([^;]+)`);
    return cookieValues ? cookieValues.pop() : '';
  }

  static cookieExists(cookieValue) {
    return typeof cookieValue === 'string' && cookieValue.trim().length > 0;
  }

  static validPpidCookie(cookieValue) {
    if (!this.cookieExists(cookieValue)) return false;
    // validate against UUID format
    const regex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
    return cookieValue.match(regex) !== null;
  }

  /**
   * Check GDPR privacy consent.Reduce load to backend server when user has not consented.
   * @return {boolean}
   */
  static canTrack() {
    // eu-site cookie and ha-cookie-settings cookie are only seen on Vrbo
    const euSiteCookie = this.getCookieValue(EU_SITE_COOKIE);
    const vrboConsentCookie = this.getCookieValue(VRBO_CONSENT_COOKIE);

    if (this.cookieExists(euSiteCookie) && euSiteCookie === '1') {
      if (this.cookieExists(vrboConsentCookie)) {
        return vrboConsentCookie.includes('functional%3A1');
      }

      // vrbo consent cookie not exist when GDPR banner is not clicked yet
      return false;
    }

    const oipCookieValue = this.getCookieValue(BEX_HCOM_CONSENT_COOKIE);
    if (!this.cookieExists(oipCookieValue)) {
      // OIP not exist for some sites where GDPR is not enforced
      return true;
    }

    if (oipCookieValue.includes('gdpr|1')) {
      // canTrack is true
      return true;
    }

    if (!oipCookieValue.includes('gdpr')) {
      // happens when a PoS contains only canSell related value
      return true;
    }

    return false;
  }

  /**
   * Delete an existing cookie by setting the value empty and expire in date in the past.
   * @param name cookie name
   */
  static deleteCookie(name) {
    const domain = this.getDomain(window.location.hostname);
    document.cookie = `${name}=; Domain=${domain}; Path=/; Max-Age=0;`;
  }

  static getDomain(hostname) {
    return hostname.replace(/(https?:\/\/)?(www.)?/i, '.');
  }
}

module.exports = {
  Util, COOKIE_NAME, PPID_COOKIE_NAME,
};
