class PixResponse {
  constructor(data) {
    this.raw = data;
    this.optout = data.o;
    if (!this.optout && data.v) {
      [this.id, this.timestamp, this.domain] = data.v.split('|');
    } else {
      this.id = null;
      this.timestamp = null;
      this.domain = null;
    }
  }

  xdidString() {
    if (this.timestamp != null && this.domain != null) {
      return `${this.id}|${this.timestamp}|${this.domain}`;
    }

    return `${this.id}`;
  }

  hasXdid() {
    return this.id != null;
  }
}

module.exports = PixResponse;
